import { useGame } from "../../game/GameContext";
import "./index.css";

export default function JoinGame() {
    const { gameState, setMultiplayerState, multiplayerState, setLoading } =
        useGame();

    if (!multiplayerState.showUserNamePage) {
        return null;
    }

    // TODO - integrate with existing user accounts.
    // if (gameState.userData)
    // {
    //     updateUsername(gameState.userData.username);
    // }

    function updateUsername() {
        const username = document.getElementById("username").value;
        if (!username) {
            console.log("error");
            return;
        }
        setMultiplayerState((prevState) => ({
            ...prevState,
            joinGame: true,
            playerOne: username,
            showUserNamePage: false,
        }));
        setLoading({
            isLoading: true,
            message: "Waiting for game to start...",
        });
    }

    return (
        <div className="join-game" data-testid="join-game">
            <form className="join-game-form">
                <h1>Enter a username</h1>

                <label htmlFor="game-id">Game ID</label>
                <input type="text" id="game-id" value={gameState.id} readOnly />

                <label htmlFor="username">Username</label>
                <input
                    type="text"
                    id="username"
                    placeholder="Username"
                    required
                />
                <input
                    type="submit"
                    className="chess-button"
                    onClick={updateUsername}
                    value={"Join Game"}
                />
            </form>
        </div>
    );
}
