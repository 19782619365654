import { Chess } from "chess.js";

/**
 * Restarts the game by creating a new Chess instance and resetting the game state.
 * Easy to win: "r3kbnr/PPP1pppp/4ppnb/5ppq/8/8/4PPPP/RNBQKBNR w KQkq - 0 1"
 * Place the restart state in the Chess constructor to start the game in that state.
 */
export function restartGame(setGameState) {
    const newGame = new Chess();
    setGameState((prev) => ({
        ...prev,
        fen: newGame.fen(),
        game: newGame,
        lastMove: null,
    }));
    localStorage.removeItem("ai-chessGameState");
    // playRestartAnimation(); removed for performance reasons
}

/**
 * Handles the home button click by closing the WebSocket connection and
 * setting the game state to the home screen.
 */
export function handleHome(setGameState, setMultiplayerState) {
    setGameState((prevState) => ({ ...prevState, showGame: false, id: null }));
    setMultiplayerState((prevState) => ({
        ...prevState,
        joinGame: false,
        ws: null,
        isHost: false,
        playerOne: null,
        playerTwo: null,
        forfeit: {
            forfeit: false,
            winner: null,
            playerID: null,
        },
    }));
}
