import { useGame } from "../../game/GameContext";
import { useEffect } from "react";
import "./index.css";
import LoadingScreen from "../../interfaces/LoadingScreen";
import { WaitingRoomConstants } from "../../../utils/constants";
const QRCode = require("qrcode");

/**
 * Creates the waiting room component. This is where the player waits for their opponent to join.
 * @param {Object} props the properties of the component.
 * @returns the JSX for the waiting room component.
 */
export default function WaitingRoom({ setWaitingForOpponent }) {
    const { gameState, multiplayerState, setMultiplayerState, setGameState } =
        useGame();

    /**
     * Creates a QR code for the game URL.
     * This is called in a useEffect hook when the game ID is set,
     * because the game ID is needed to create the QR code.
     */
    function createQRCode() {
        const url = window.location + "#" + gameState.id;
        const canvas = document.getElementById("qr-code");
        QRCode.toCanvas(
            canvas,
            url,
            WaitingRoomConstants.QR_CODE_CONFIG,
            handleQRCodeError,
        );
    }

    /**
     * Handles an error when creating the QR code.
     * TODO could add a retry.
     */
    function handleQRCodeError() {
        return;
    }

    // Create the QR code when the game ID is set.
    useEffect(() => {
        if (gameState.id) {
            console.log(gameState.id);
            createQRCode();
        }
    }, [gameState.id, createQRCode]);

    /**
     * Handles clicking on the cancel button in the waiting room.
     * Deletes the game and closes the WS connection.
     */
    function handleClickBack() {
        if (multiplayerState.ws) {
            multiplayerState.ws.send(
                JSON.stringify({ action: "end", gameID: gameState.id }),
            );
            multiplayerState.ws.close();
        }
        setGameState((prev) => ({ ...prev, id: null }));
        setMultiplayerState((prev) => ({
            ...prev,
            ws: null,
            isHost: false,
            playerOne: null,
            playerTwo: null,
        }));
        setWaitingForOpponent(false);
    }

    /* 
    When the game ID is null, show the loading screen. 
    Once the backend creates the game, the game ID will be set.
    */
    if (gameState.id === null) {
        return <LoadingScreen message={"Creating game..."} />;
    }

    return (
        <div className="waiting-room" data-testid="waiting-room">
            <div className="columns">
                <div className="row">
                    <h1>Join the game!</h1>
                    <p>
                        Scan the QR code with a mobile device to join the game.
                    </p>
                    <div className="dot-elastic"></div>
                    <div className="player-wrapper">
                        <div className="player">
                            {multiplayerState.playerOne || "?"}
                        </div>
                        VS
                        <div className="player">
                            {multiplayerState.playerTwo || "?"}
                        </div>
                    </div>
                    <button id="cancel-button" onClick={handleClickBack}>
                        Cancel
                    </button>
                    {process.env.NODE_ENV === "development" && (
                        <a href={"#" + gameState.id}>Temp dev link</a>
                    )}
                </div>
                <div>
                    <canvas id="qr-code"></canvas>
                </div>
            </div>
        </div>
    );
}
