import { KeyCodes } from "./KeyCodes.js";
export let activeButton = 0;
let elements;

/**
 * Handles a key down event.
 * @param {Event} event
 * @param {GameProvider} gameState
 */
export function handleKeyDown(event) {
    if (window.location.hash) {
        return;
    }
    event.preventDefault();
    elements = document.querySelectorAll("button");
    const numButtons = elements.length;

    if (KeyCodes.Downs.includes(event.keyCode)) {
        if (activeButton < numButtons - 1) {
            activeButton++;
        }
    } else if (KeyCodes.Ups.includes(event.keyCode)) {
        if (activeButton > 0) {
            activeButton--;
        }
    } else if (event.keyCode === KeyCodes.Enter) {
        elements[activeButton]?.click();
    }

    const activeElement = elements[activeButton];
    if (activeElement?.tagName === "BUTTON") {
        activeElement?.focus();
    }
}
