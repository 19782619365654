/**
 * MultiplayerMenu component.
 * All host side multiplayer logic occurs here.
 *
 * @author Braden Zingler
 * Last modified 10/04/2024
 */
import "./index.css";
import { useGame } from "../../game/GameContext";
import React, { useState } from "react";
import { MultiplayerConstants } from "../../../utils/constants";
import WaitingRoom from "../WaitingRoom";
// import { MdCancel } from "react-icons/md";
import {
    handleClose,
    handleError,
    handleHost,
    handleMessage,
} from "../../../utils/multiplayer/handlers";

/**
 * The MultiplayerMenu component.
 * Creates a menu for hosting a multiplayer game.
 * Handles the logic for hosting the multiplayer game.
 * @param {Boolean} showMultiplayer Flag to show/hide the multiplayer menu.
 * @param {Function} setShowMultiplayer Function to toggle the multiplayer menu.
 * @returns {JSX.Element} The MultiplayerMenu component.
 */
export default function MultiplayerMenu() {
    const {
        showMultiplayerMenu,
        setShowMultiplayerMenu,
        gameState,
        setGameState,
        setMultiplayerState,
        setLoading,
    } = useGame();
    const [waitingForPlayers, setWaitingForPlayers] = useState(false);

    if (!showMultiplayerMenu) {
        return null;
    }

    /**
     * Host a new multiplayer game.
     * Read the planning document for more information:
     * https://sinclairdigital.atlassian.net/wiki/spaces/DL/pages/1103265798/Chess+MVP+Multiplayer+Implementation#Hosting-Games
     */
    function hostGame() {
        // Disable the back button so users cannot go back once a game has been created.
        document.getElementById("multiplayer-menu-back-button").disabled = true;
        setGameState((prev) => ({ ...prev, isAIGame: false, lastMove: null }));
        setMultiplayerState((prev) => ({
            ...prev,
            playerColor: "w",
            isHost: true,
        }));

        if (gameState.id) {
            // A game was already there, delete it and make a new game.
            setGameState((prev) => ({ ...prev, id: null }));
            setMultiplayerState((prev) => ({
                ...prev,
                ws: null,
                playerOne: null,
                playerTwo: null,
            }));
            return;
        }

        let newWS = new WebSocket(MultiplayerConstants.WS_URL);
        let interval;

        newWS.onopen = () => {
            // Re-enable the button.
            document.getElementById("multiplayer-menu-back-button").disabled =
                true;
            setWaitingForPlayers(true);
            setLoading({ isLoading: false, message: "" });
            handleHost(newWS, setMultiplayerState);
            interval = setInterval(() => {
                newWS.send(JSON.stringify({ action: "ping" }));
            }, MultiplayerConstants.PING_INTERVAL);
        };

        newWS.onclose = () => {
            handleClose(
                setMultiplayerState,
                gameState,
                setGameState,
                waitingForPlayers,
            );
            clearInterval(interval);
        };

        newWS.onerror = (error) => {
            handleError(error);
        };

        newWS.onmessage = (message) => {
            handleMessage(
                message,
                setGameState,
                setMultiplayerState,
                setLoading,
            );
            if (message === "pong") return;
            if (typeof message.data !== "string") {
                setWaitingForPlayers(
                    !JSON.parse(message.data)?.gameStatus?.ready,
                );
            }
        };
        setMultiplayerState((prev) => ({
            ...prev,
            ws: newWS,
            showUserNamePage: false,
        }));
    }

    /**
     * Delete a game when user presses red X.
     * Opens the WS to delete the game.
     */
    // function deleteGame() {
    //     setGameState({ fen: null, id: null });
    //     const ws = new WebSocket(MultiplayerConstants.WS_URL);
    //     ws.onopen = () => {
    //         const msg = JSON.stringify({ action: "end", gameID: gameState.id });
    //         ws.send(msg);
    //         ws.close();
    //     };
    // }

    /* Show the waiting room while waiting for an opponent to join */
    if (waitingForPlayers) {
        return <WaitingRoom setWaitingForOpponent={setWaitingForPlayers} />;
    }

    return (
        <div className="multiplayer-menu" data-testid="multiplayer-menu">
            <h1>Multiplayer</h1>
            {/* {gameState.id ? (
                <div id="game-row">
                    <button onClick={hostGame} className="chess-button">
                        Game 1
                    </button>
                    <MdCancel id="icon-cancel" size={50} onClick={deleteGame} />
                </div>
            ) : ( */}
            <button onClick={hostGame} className="chess-button">
                Host Game
            </button>
            {/* )} */}
            <button
                onClick={() => setShowMultiplayerMenu(false)}
                className="chess-button"
                id="multiplayer-menu-back-button"
            >
                Back
            </button>
        </div>
    );
}
