/**
 * GameContext
 * Context to manage the game state.
 * This is wrapped around the entire app to manage the game state.
 *
 * @component GameContext
 * @returns {JSX.Element} The context provider for the game state.
 * @author Braden Zingler
 * Last modified 09/20/2024
 */
import React, { createContext, useContext, useState } from "react";

const GameContext = createContext();
export const useGame = () => useContext(GameContext);

export const GameProvider = ({ children }) => {
    const [showMultiplayerMenu, setShowMultiplayerMenu] = useState(false);
    const [remoteNavigation, setRemoteNavigation] = useState({
        remoteOverSquare: "a1",
        remoteClickSquare: null,
    });
    const [loading, setLoading] = useState({
        isLoading: false,
        message: null,
    });
    const [gameState, setGameState] = useState({
        fen: null,
        id: null,
        game: null,
        lastMove: null,
        isAIGame: false,
        aiDifficulty: 1,
        showGame: false,
        userData: null,
    });
    const [multiplayerState, setMultiplayerState] = useState({
        isHost: false,
        playerColor: null,
        playerOne: null,
        playerTwo: null,
        ws: null,
        forfeit: {
            forfeit: false,
            winner: null,
            playerID: null,
        },
        fullGame: false,
        joinGame: false,
        showUserNamePage: false,
    });
    const [promotion, setPromotion] = useState({
        open: false,
        type: null,
    });

    return (
        <GameContext.Provider
            value={{
                loading,
                setLoading,
                showMultiplayerMenu,
                setShowMultiplayerMenu,
                gameState,
                setGameState,
                multiplayerState,
                setMultiplayerState,
                promotion,
                setPromotion,
                remoteNavigation,
                setRemoteNavigation,
            }}
        >
            {children}
        </GameContext.Provider>
    );
};
